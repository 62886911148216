


     .table-auto {
      width: 100%;
      font-size: 10pt;
    }
  
    .table-auto td,
    .table-auto th {
      padding: 0.5rem;
    }
  
    .table-auto th:nth-child(1) {
      width: 20%;
    }
  
    .table-auto th:nth-child(2) {
      width: 15%;
    }
  
    .table-auto th:nth-child(3) {
      width: 15%;
    }
  
    .table-auto th:nth-child(4),
    .table-auto td:nth-child(4) {
      width: 10%;
    }
  
    .table-auto th:nth-child(5),
    .table-auto td:nth-child(5) {
      width: 10%;
    }
  
    .table-auto th:nth-child(6),
    .table-auto td:nth-child(6) {
      width: 10%;
    }
  
    .table-auto th:nth-child(7),
    .table-auto td:nth-child(7) {
      width: 10%;
    }
  
    .table-auto th:nth-child(8) {
      width: 10%;
    }
  
    .table-auto th:nth-child(9) {
      width: 5%;
    }
  
  