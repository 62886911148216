@tailwind base;
@tailwind components;
@tailwind utilities;

@media print {
  .table-auto {
    width: 100%;
    font-size: 10pt;
  }

  .table-auto td,
  .table-auto th {
    padding: 0.5rem;
  }

  .table-auto th:nth-child(1) {
    width: 20%;
  }

  .table-auto th:nth-child(2) {
    width: 15%;
  }

  .table-auto th:nth-child(3) {
    width: 15%;
  }

  .table-auto th:nth-child(4),
  .table-auto td:nth-child(4) {
    width: 10%;
  }

  .table-auto th:nth-child(5),
  .table-auto td:nth-child(5) {
    width: 10%;
  }

  .table-auto th:nth-child(6),
  .table-auto td:nth-child(6) {
    width: 10%;
  }

  .table-auto th:nth-child(7),
  .table-auto td:nth-child(7) {
    width: 10%;
  }

  .table-auto th:nth-child(8) {
    width: 10%;
  }

  .table-auto th:nth-child(9) {
    width: 5%;
  }
}


.color {
  background: #181616;
}

.text-error {
  @apply text-red-500 dark:text-[#919191];
}

/* Hamburger start */
.hamburger-line {
  @apply w-6 h-[2px] my-[5px] block bg-black rounded-lg transition duration-300 ease-in-out bg-purple-600;
}

.hamburger-active > span:nth-child(1) {
  @apply rotate-[38deg];
}

.hamburger-active > span:nth-child(2) {
  @apply scale-0;
}

.hamburger-active > span:nth-child(3) {
  @apply -rotate-[38deg];
}
/* Hamburger End */

/* Side bar */
.active.sidebar-active {
  @apply dark:text-gray-100 text-gray-800;
}

.svg-updown {
  transform: rotate(0);
  animation: uprotate-svg 0.5s;
}

@keyframes uprotate-svg {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.svg-dropdown {
  transform: rotate(180deg);
  animation: rotate-svg 0.5s;
}

@keyframes rotate-svg {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(180deg);
  }
}

.dropdown-animation {
  animation: rotateMenu 400ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }
  70% {
    transform: rotateX(20deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

.sidebar-open-mobile {
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}
/* Sidebar end */

/* Scrool bar */
/* width */
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar:horizontal {
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 8px;
}
/* Scrool bar end */

.active-paginate {
  @apply bg-primary text-white;
}

.disable-paginate {
  @apply bg-gray-300 hover:cursor-default;
}

/* Loading Process */
.loadingProcess {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 1rem;
  --tw-backdrop-blur: blur(4px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
}
